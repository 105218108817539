'use client';

import React from 'react';
import dayjs from 'dayjs';
import Image from 'next/image';
import { validateEmail } from '@shared/utils/validator';
import { AppRoutes } from '@shared/constants/appRoutes';
import { useRouter, useSearchParams } from 'next/navigation';
import { Button, Form, Input, Modal, Typography } from 'antd';
import registerPopupBg from '@shared/icons/register-popup.svg';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';

const popupTimeout = Number(process.env.NEXT_PUBLIC_DISPLAY_POPUP_TIMEOUT);

let timeout: NodeJS.Timeout;

export const PopupPrompting: React.FC<{
  user?: {
    name: string;
    handle: string;
    tiktok?: string;
    youtube?: string;
    vm_verify: number;
    created_at: string;
    instagram?: string;
    default_plan: string;
    country: null | string;
    og_image: null | string;
    profile_photo_path: string;
  };
}> = ({ user }) => {
  const { push } = useRouter();
  const postHog = useLocalPostHog();
  const searchParams = useSearchParams();
  const [error, setError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [email, handleEmailChange] = React.useState('');

  React.useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setOpen(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');

    handleEmailChange(e.target.value);
  };

  if (!user) {
    return null;
  }

  const isPaid = user.default_plan !== 'free';

  if (isPaid) {
    return null;
  }

  if (Number.isNaN(popupTimeout)) {
    return null;
  }

  const diffInMinutes = dayjs().diff(dayjs(user.created_at), 'minutes');

  if (diffInMinutes < popupTimeout) {
    return null;
  }

  return (
    <Modal
      centered
      closable
      open={open}
      footer={null}
      width="fit-content"
      onClose={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      styles={{ content: { padding: 0, overflow: 'hidden' } }}
      afterClose={() => {
        try {
          postHog?.capture(PosthogEvents.CloseRegisterFromPopup, {
            email: email || '',
          });
        } catch (e) {
          console.log(e);
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Image width={361} height={200} src={registerPopupBg} alt="" />
        <div
          style={{
            gap: 16,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '16px 24px 16px 24px',
          }}
        >
          <Typography.Text strong style={{ fontSize: 20, lineHeight: '28px' }}>
            Monetize Your Social Media
          </Typography.Text>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Form.Item
              help={error}
              validateStatus={!!error ? 'error' : undefined}
            >
              <Input
                type="email"
                size="large"
                value={email}
                onChange={onChange}
                style={{ width: 300 }}
                placeholder="Email Address"
              />
            </Form.Item>

            <Button
              size="large"
              type="primary"
              onClick={() => {
                if (!validateEmail(email)) {
                  setError('Please enter a valid email address.');

                  return;
                }

                const params = searchParams.toString();

                try {
                  postHog?.capture(PosthogEvents.RegisterFromPopup, {
                    email,
                  });
                } catch (e) {
                  console.log(e);
                }

                sessionStorage.setItem('email', email);
                if (params) {
                  push(`${AppRoutes.registerStep1}?${params}&email=${email}`);
                } else {
                  push(`${AppRoutes.registerStep1}?email=${email}`);
                }
              }}
              style={{ width: 140, transform: 'translateY(-8px)' }}
            >
              Create Media Kit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
