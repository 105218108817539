'use client';

import React from 'react';
import { Layout } from 'antd';
import { Content } from './ui/content';
import { Profile } from './ui/profile';
import { Container } from './ui/container';
import { Background } from './ui/background';
import { Builder } from '@shared/types/builder';
import { MkitHeader } from './ui/mkit-header';
import { GlobalStyles } from './ui/global-styles';
import { layoutStyles } from '@shared/styles/common';
import { Categories } from '@shared/types/categories';
import { Layout as ILayout } from 'react-grid-layout';
import { selectPreviewMode, useSelector } from '@shared/redux';
import { PopupPrompting } from '@widgets/mediakit/register-modal';
import { CreateYourMediaKit } from '@widgets/create-your-media-kit';
import { GridLayoutServer } from '@widgets/mediakit/grid-layout/server';
import { LayoutBreakpoints } from '@shared/constants/layoutBreakpoints';
import { CreateMediaKitContainer } from './ui/create-media-kit-container';

export function MediaKitServer({
  user,
  theme,
  visits,
  layouts,
  mediakit,
  isPreview,
  extraData,
  categories,
  isAuthenticated,
}: {
  theme: any;
  visits?: any;
  user?: {
    name: string;
    handle: string;
    tiktok?: string;
    youtube?: string;
    vm_verify: number;
    created_at: string;
    instagram?: string;
    default_plan: string;
    country: null | string;
    og_image: null | string;
    profile_photo_path: string;
  };
  layouts: {
    [LayoutBreakpoints.sm]: ILayout[];
    [LayoutBreakpoints.xxs]: ILayout[];
  };
  isPreview?: boolean;
  isAuthenticated?: boolean;
  categories: Categories.List;
  extraData: Builder.ExtraData;
  mediakit: Builder.Mediakit.Mediakit;
}) {
  const previewMode = useSelector(selectPreviewMode);

  const [opacity, setOpacity] = React.useState<number>(1);

  React.useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 400);
  }, []);

  const styles = Object.assign({}, layoutStyles, {
    opacity,
  });

  const isMobile = previewMode === 'mobile' && isPreview;

  return (
    <Layout style={styles}>
      <GlobalStyles fonts={theme?.fonts} />

      <Background theme={theme} isPreview={isPreview} />

      <React.Suspense fallback="Loading">
        <MkitHeader isPreview={isPreview} handle={mediakit.handle} />
      </React.Suspense>

      <Content isMobile={isMobile}>
        <Container isMobile={isMobile}>
          <Profile
            user={user}
            theme={theme}
            visits={visits}
            mediakit={mediakit}
            isPreview={isPreview}
            categories={categories}
          />
          <GridLayoutServer
            theme={theme}
            layouts={layouts}
            isPreview={isPreview}
            extraData={extraData}
            handle={mediakit.handle}
          />
          <CreateMediaKitContainer>
            <CreateYourMediaKit
              theme={theme}
              visits={visits}
              username={mediakit.handle}
            />
          </CreateMediaKitContainer>
        </Container>
      </Content>

      {!isAuthenticated && !isPreview && <PopupPrompting user={user} />}
    </Layout>
  );
}
