'use client';

import React from 'react';
import { Box } from '@shared/ui/box';
import { prefixCls } from '@shared/theme';
import { sleep } from '@shared/utils/sleep';
import { Text } from '@shared/ui/typography';
import { CheckOutlined } from '@ant-design/icons';
import styled, { CSSObject } from '@emotion/styled';
import { CtaLabels } from '@shared/constants/ctaLabels';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { Alert, Button, Form, Input, Modal } from 'antd';
import { fontVariants } from '@shared/constants/fontVariants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { Controller, useForm, useFormState, useWatch } from 'react-hook-form';
import { useSubmitContactFormMutation } from '@shared/services/contactCreator';
import { PosthogEvents } from '@shared/constants/posthog-events';

const ContactForm: React.FC<any> = ({ toggleModal, handle, isPreview }) => {
  const postHog = useLocalPostHog();

  const [submit] = useSubmitContactFormMutation();

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const onSubmit = async (formValues: any) => {
    if (isPreview) {
      return;
    }

    try {
      await sleep(400);

      try {
        postHog?.capture(PosthogEvents.ContactForm, {
          ...formValues,
          username: handle,
          source: 'cta_button',
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }

      await submit({
        ...formValues,
        username: handle,
      }).unwrap();
    } catch (e: any) {
      Object.keys(e.errors || {}).forEach((inputName) => {
        if (allowedInputs.includes(inputName)) {
          setError(inputName, { message: e.errors[inputName] });
        } else if (inputName === 'handle') {
          setError('root', { message: e.errors[inputName] });
        }
      });
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form layout="vertical" component="div">
        {!!errors.root?.message && (
          <Box boxStyles={{ marginBottom: 24 }}>
            <Alert type="error" message={errors.root?.message} closable />
          </Box>
        )}
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              required
              label="Email"
              help={error?.message}
              validateStatus={!!error?.message ? 'error' : undefined}
            >
              <Input
                {...field}
                type="email"
                name="email"
                autoComplete="email"
                size="large"
              />
            </Form.Item>
          )}
        />

        <Controller
          name="full_name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              required
              label="Full Name"
              help={error?.message}
              validateStatus={!!error?.message ? 'error' : undefined}
            >
              <Input
                {...field}
                type="text"
                name="full_name"
                autoComplete="off"
                size="large"
              />
            </Form.Item>
          )}
        />

        <Controller
          name="brand_name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              required
              label="Brand Name"
              help={error?.message}
              validateStatus={!!error?.message ? 'error' : undefined}
            >
              <Input
                {...field}
                type="text"
                name="brand_name"
                size="large"
                autoComplete="off"
              />
            </Form.Item>
          )}
        />

        <Controller
          name="message"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              required
              label="Message"
              help={error?.message}
              validateStatus={!!error?.message ? 'error' : undefined}
            >
              <Input.TextArea {...field} size="large" rows={4} name="message" />
            </Form.Item>
          )}
        />
      </Form>

      <Box boxStyles={{ gap: 8, justifyContent: 'flex-end' }}>
        <Button size="large" onClick={() => toggleModal(false)}>
          Cancel
        </Button>

        <SubmitButton
          isPreview={isPreview}
          toggleModal={toggleModal}
          control={control}
        />
      </Box>
    </form>
  );
};

const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'buttonStyles',
})<{
  buttonStyles?: CSSObject;
}>(({ buttonStyles }) => ({
  position: 'relative',
  ...(buttonStyles as any),
}));

const SubmitButton: React.FC<any> = ({ control, toggleModal, isPreview }) => {
  const { isSubmitting, isSubmitSuccessful } = useFormState({ control });

  const [email, full_name, brand_name, message] = useWatch({
    control,
    name: ['email', 'full_name', 'brand_name', 'message'],
  });

  React.useEffect(() => {
    if (isPreview) {
      return;
    }
    if (isSubmitSuccessful) {
      setTimeout(function () {
        toggleModal(false);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful, isPreview]);

  const disabled =
    !email?.trim() ||
    !message?.trim() ||
    !full_name?.trim() ||
    !brand_name?.trim();

  return (
    <Button
      size="large"
      type="primary"
      htmlType="submit"
      disabled={disabled}
      loading={isPreview ? undefined : isSubmitting}
      icon={
        isSubmitSuccessful ? (
          isPreview ? undefined : (
            <CheckOutlined />
          )
        ) : undefined
      }
    >
      Send
    </Button>
  );
};

const allowedInputs = ['email', 'message', 'full_name', 'brand_name'];

export const CTAButton: React.FC<any> = ({
  theme,
  mediakit,
  isPreview,
  buttonTextColor,
}) => {
  const matches = useBreakpoint(768);
  const [modalIsOpen, toggleModal] = React.useState(false);
  const name = mediakit?.content?.profile?.name?.value || mediakit.handle;

  return (
    <>
      <Modal
        closable
        footer={null}
        destroyOnClose
        open={modalIsOpen}
        maskClosable={false}
        width={matches ? undefined : 420}
        onCancel={() => toggleModal(false)}
        title={
          <Text
            ellipsis={{ tooltip: true }}
            style={{
              fontSize: 'inherit',
              fontWeight: 'inherit',
              width: 'calc(100% - 25px)',
            }}
          >
            Contact {name}
          </Text>
        }
        styles={{ body: { paddingTop: 24 }, content: { paddingBlock: 16 } }}
      >
        <ContactForm
          isPreview={isPreview}
          handle={mediakit.handle}
          toggleModal={toggleModal}
        />
      </Modal>
      <StyledButton
        block
        size="large"
        type="primary"
        className={fontVariants.opt2}
        onClick={() => toggleModal(true)}
        buttonStyles={{
          [`&.${prefixCls}-btn-primary:not(:disabled):not(.${prefixCls}-btn-disabled)`]:
            {
              color: buttonTextColor,
              backgroundColor: theme?.primary_color || void 0,
              boxShadow: theme?.primary_color ? 'none' : void 0,
              '&:hover': {
                backgroundColor: `${theme?.primary_color}E0` || void 0,
              },
            },
        }}
      >
        {mediakit.content?.profile?.cta || CtaLabels['Partner with me']}
      </StyledButton>
    </>
  );
};
