import React from 'react';
import { Card } from './ui/card';
import styled from '@emotion/styled';
import { Visitors } from './ui/visitors';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import { prefixCls, shadow } from '@shared/theme';
import { useSearchParams } from 'next/navigation';
import { AppRoutes } from '@shared/constants/appRoutes';
import { abbreviateNumber } from '@shared/utils/abbreviateNumber';

const StyledButton = styled(Button)(() => ({
  [`&.${prefixCls}-btn`]: {
    left: 0,
    right: 0,
    width: 245,
    zIndex: 92,
    bottom: 32,
    border: 'none',
    display: 'none',
    fontWeight: 500,
    borderRadius: 99,
    color: '#5956E9',
    position: 'fixed',
    boxShadow: shadow,
    marginInline: 'auto',
    alignItems: 'center',
    '& > span': { display: 'flex' },
    '@media (max-width: 1210px)': {
      display: 'flex',
    },
  },
}));

const VisitorsButton: React.FC<{ handle: string }> = ({ handle }) => {
  const searchString = useSearchParams().toString();

  let registerHref = `${AppRoutes.registerStep1}?utm_source=mkit_share_get_started&utm_content=${handle}`;
  if (searchString) {
    registerHref = `${registerHref}&${searchString}`;
  }

  const onClick = () => {
    if (typeof window !== 'undefined') {
      window.location.href = registerHref;
    }
  };

  return (
    <StyledButton size="large" icon={btnIcon} onClick={onClick}>
      Create Your Media Kit
    </StyledButton>
  );
};

const btnIcon = (
  <svg
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#5956E9"
      d="M11.5611 8.19572C11.5611 10.104 9.95611 11.6697 8 11.6697C6.04389 11.6697 4.43887 10.104 4.43887 8.19572V7.80428C4.43887 5.89602 6.04389 4.33028 8 4.33028H11.5611V8.19572ZM11.5611 0H8C3.58621 0 0 3.49847 0 7.80428V8.19572C0 12.5015 3.58621 16 8 16C12.4138 16 16 12.5015 16 8.19572V4.33028C16 1.95719 14.0188 0 11.5611 0Z"
    />
  </svg>
);

export const CreateYourMediaKit: React.FC<{
  theme: any;
  visits: any;
  username: string;
}> = ({ username, visits }) => {
  return (visits?.total || 0) >= 50 ? (
    <>
      <Card>
        <Flex align="center" justify="flex-start" gap={8}>
          <Typography.Text type="secondary">
            <EyeOutlined />
          </Typography.Text>
          <Typography.Text>Media Kit Visits</Typography.Text>
        </Flex>
        <Flex>
          <Visitors>{abbreviateNumber(visits?.total)}</Visitors>
        </Flex>
      </Card>
      <React.Suspense>
        <VisitorsButton handle={username} />
      </React.Suspense>
    </>
  ) : null;
};
