'use client';

import React from 'react';
import { Flex, Tooltip, Typography } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { abbreviateNumber } from '@shared/utils/abbreviateNumber';

export const TotalFollowers: React.FC<{ totalFollowers?: number }> = ({
  totalFollowers,
}) => {
  return !totalFollowers ? null : (
    <Flex gap={8} align="center" justify="center" style={{ width: '100%' }}>
      <Typography.Text type="secondary">Total Followers:</Typography.Text>
      <Flex align="center" justify="center">
        <Typography.Text strong>
          {abbreviateNumber(totalFollowers)}
        </Typography.Text>
        &nbsp;
        <Tooltip title="Instagram, TikTok, YouTube followers">
          <Typography.Text type="secondary">
            <InfoCircleFilled />
          </Typography.Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
